<template>
  <div class="my_container">
    <van-nav-bar title="我的eSIM"
                 color="red"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />

    <van-grid :column-num="2"
              :border="true"
              clickable>
      <van-grid-item text="ddd文字">
        <van-image src="hongkong.png"
                   style="height: 20px;" />
        <div style="margin-top: 8px; font-size: 12px;font-weight: 700;">2</div>
      </van-grid-item>
      <van-grid-item text="ddd文字"
                     to="/myesim">
        <van-image src="canada.png"
                   style="height: 20px;" />
        <div style="margin-top: 8px; font-size: 12px;font-weight: 700;">3</div>
      </van-grid-item>
    </van-grid>

    <div class="esim_list">
      <van-list finished-text="没有更多了"
                @load="onLoad">
        <van-cell v-for="item in list"
                  :key="item"
                  :title="item.num">
          <template #title>
            <span class="custom-title">{{item.num}}</span>
            <van-tag type="primary"
                     style="margin-left: 5px;"
                     v-if="item.status == 0">正常</van-tag>
            <van-tag type="primary"
                     style="margin-left: 5px;"
                     color="#ffe1e1"
                     text-color="#ad0000"
                     v-else>异常</van-tag>
          </template>
          <div>{{item.country}}</div>
          <div v-if="item.district != ''">{{item.district}}</div>
          <van-button size="mini"
                      color="red"
                      v-if="item.status == 1"
                      style="padding: 0 10px">
            处理
          </van-button>
          <!-- <div>状态：正常</div>
          <div>状态：欠费</div> -->
        </van-cell>

      </van-list>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          num: '+852 81234567',
          cost: 5,
          country: '香港',
          district: '',
          status: 0
        },
        {
          num: '+852 95326701',
          cost: 6,
          country: '香港',
          district: '',
          status: 0
        },
        {
          num: '+1 807 1497132',
          cost: 10,
          country: '加拿大',
          district: 'Fort William',
          status: 1
        },

        {
          num: '+1 780 64931629',
          cost: 18,
          country: '加拿大',
          district: 'Alberta (Northern)',
          status: 0
        },
        {
          num: '+1 403 85613065',
          cost: 11,
          country: '加拿大',
          district: 'Alberta (Southern)',
          status: 0
        }
      ]
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onLoad() {
      return
    }
  }
}
</script>

<style scoped lang="less">
.my_container {
  padding: 40px 10px 0 10px;
  /* /deep/的意思大概为深入的 */
  //修改文字颜色
  /deep/ .van-nav-bar__text {
    color: #000000;
  }
  //修改箭头颜色
  /deep/ .van-icon-arrow-left {
    color: var(--nav-bar-icon-color, #ff9000) !important;
  }
  //修改背景颜色
  /deep/ .van-nav-bar {
    // background-color: #e40000;
  }
  .head {
    .title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    // background-color: green;
  }
  .mycenter {
    margin-top: 30px;
  }
  .esim_list {
    margin-top: 15px;
    font-size: 10px;
    .custom-title {
      font-weight: 700;
    }
  }
}
</style>
