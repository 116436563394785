import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import BuySim from '../views/BuySim.vue'
import MineView from '../views/MineView.vue'
import MyeSIM from '../views/MySim.vue'
import MyWallet from '../views/MyWallet.vue'
const routes = [{
    path: '/',
    redirect: '/buysim'
  },
  {
    path: '/buysim',
    name: 'buysim',
    component: BuySim
  },
  {
    path: '/mine',
    name: 'mine',
    component: MineView
  },
  {
    path: '/myesim',
    name: 'myesim',
    component: MyeSIM
  }, {
    path: '/mywallet',
    name: 'mywallet',
    component: MyWallet
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router