import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
// import {
//   Tabbar,
//   TabbarItem
// } from 'vant';
import Vant from 'vant';

// createApp(App).use(store).use(router).use(Tabbar).use(TabbarItem).mount('#app')
const app = createApp(App)
app.use(Vant);
app.use(Vant.Lazyload);
app.use(store);
app.use(router);
// app.use(Tabbar);
// app.use(TabbarItem);
app.mount('#app');