<template>

  <router-view />
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <div class="m-mobilea">
    <van-tabbar v-if="activeIndex"
                v-model="active"
                route>
      <van-tabbar-item replace
                       to="/buysim"
                       icon="home-o">
        <span>市场</span>
        <template #icon="props">
          <img :src="props.active ? 'market_active.png' : 'market_inactive.png'" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace
                       to="/mine"
                       icon="user-o">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? 'my_active.png' : 'my_inactive.png'" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 1,
      activeIndex: 1, // 头部导航栏选中的标签
      search: '', // 搜索条件
      // 打开详情
      isDetail: true,
      openId: ''
    }
  },
  beforeUpdate() {
    this.pageMode()
  },
  mounted() {
    // console.log(window.location, this.$route)
    this.pageMode()
  },
  methods: {
    pageMode() {
      console.log('3333333333333333333333333', this.$route.path)
      let mainPage = ['', '/', '/buysim', '/mine']
      this.activeIndex = 0
      for (let m = 0; m < mainPage.length; m++) {
        if (this.$route.path == mainPage[m]) {
          this.activeIndex = 1
        }
      }
      console.log(this.activeIndex)
    },
    // 点击搜索按钮
    searchClick() {
      if (this.search != '') {
        // 跳转到全部商品页面,并传递搜索条件
        this.$router.push({ path: '/goods', query: { search: this.search } })
        this.search = ''
      }
    }
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // background-color: pink;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
