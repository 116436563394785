<template>
  <div class="buysim">
    <div>
      <van-row>
        <van-col span="24">
          <div style="margin: 15px 0;">
            <img src="ARK.png"
                 alt=""
                 height="30px">
          </div>
        </van-col>
      </van-row>
    </div>

    <div class="guanggao">
      <van-row gutter="20">
        <van-col span="12">
          <div class="item">
            <img src="left.png"
                 alt="">
            <div class="tip">特别优惠</div>
          </div>
        </van-col>
        <van-col span="12">
          <div class="item">
            <img src="right.png"
                 alt="">
            <div class="tip">免费试用</div>
          </div>
        </van-col>
      </van-row>
    </div>

    <div class="xuanze">
      <div class="title">请选择号码</div>
      <div class="tip">拥有80个国家5000多个城市手机号码</div>
    </div>

    <div class="search">
      <van-search v-model="searchInput"
                  shape="round"
                  placeholder="请输入搜索关键词" />
    </div>

    <div class="numlist">
      <van-collapse v-model="activeNames"
                    class="my-collapse">
        <van-collapse-item name="1">
          <template #title>
            <div style="margin-left: 10px; font-weight: 700;"> 香港</div>
          </template>
          <template #icon>
            <img src="hongkong.png"
                 alt=""
                 height="30px">
          </template>

          <div class="nlist"
               v-for="(eitem,index) in hongkong"
               :key='eitem.num'
               @click="hongkongClick(index)">

            <van-row>
              <van-col span="12">
                <div>
                  {{ eitem.num }}
                </div>
              </van-col>
              <van-col span="12">
                <div style="text-align: right;margin-right: 5px; font-weight: 700">
                  {{ eitem.cost }}
                  <span style="font-weight: 400;">USDT</span>
                </div>
              </van-col>
            </van-row>
          </div>

        </van-collapse-item>
        <van-collapse-item name="2">
          <template #title>
            <div style="margin-left: 10px; font-weight: 700;"> 加拿大</div>
          </template>
          <template #icon>
            <img src="canada.png"
                 alt=""
                 height="28px">
          </template>
          <van-collapse v-model="activeNames">
            <van-collapse-item title="(807) Fort William"
                               name="21">
              <div class="nlist"
                   v-for="(eitem,index) in canada['Fort William']"
                   :key='eitem.num'
                   @click="canadaClick(0,index)">
                <div v-if="eitem.district=='Fort William'">
                  <van-row>
                    <van-col span="12">
                      <div>
                        {{ eitem.num }}
                      </div>
                    </van-col>
                    <van-col span="12">
                      <div style="text-align: right;margin-right: 5px; font-weight: 700">
                        {{ eitem.cost }}
                        <span style="font-weight: 400;">USDT</span>
                      </div>
                    </van-col>
                  </van-row>
                </div>

              </div>
            </van-collapse-item>
            <van-collapse-item title="(780) Alberta (Northern)"
                               name="22">
              <div class="nlist"
                   v-for="(eitem,index) in canada['Alberta (Northern)']"
                   :key='eitem.num'
                   @click="canadaClick(1,index)">
                <van-row>
                  <van-col span="12">
                    <div>
                      {{ eitem.num }}
                    </div>
                  </van-col>
                  <van-col span="12">
                    <div style="text-align: right;margin-right: 5px; font-weight: 700">
                      {{ eitem.cost }}
                      <span style="font-weight: 400;">USDT</span>
                    </div>
                  </van-col>
                </van-row>
              </div>
            </van-collapse-item>
            <van-collapse-item title="(403) Alberta (Southern)"
                               name="23">
              <div class="nlist"
                   v-for="(eitem,index) in canada['Alberta (Southern)']"
                   :key='eitem.num'
                   @click="canadaClick(2,index)">
                <van-row>
                  <van-col span="12">
                    <div>
                      {{ eitem.num }}
                    </div>
                  </van-col>
                  <van-col span="12">
                    <div style="text-align: right;margin-right: 5px; font-weight: 700">
                      {{ eitem.cost }}
                      <span style="font-weight: 400;">USDT</span>
                    </div>
                  </van-col>
                </van-row>
              </div>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>

    </div>

  </div>
  <van-popup v-model:show="showPopup"
             position="bottom"
             round
             closeable
             close-icon="close"
             :style="{ padding: '20px',height: '90%'}"
             :data="popData"
             class="detail">
    <div style="font-weight: 700;">号码详情</div>
    <div class="detail_header">
      <div>{{popData.num}}</div>
      <div class="fun">
        <span><img src="callin.png"
               alt=""></span>
        <span><img src="callout.png"
               alt=""></span>
        <span><img src="message.png"
               alt=""></span>
      </div>
      <snap>{{popData.country}}</snap>
      <snap v-if="popData.district != ''"> - {{popData.district}}</snap>
    </div>

    <div class="tcdetail">
      <div style="text-align: left; margin-bottom: 10px; font-weight: 700; font-size: 13px">套餐详情</div>
      <van-collapse v-model="activeNamesa"
                    class="my-collapsea">
        <van-collapse-item title="月租:"
                           name="1"
                           :readonly="true"
                           :border="false">
          <div style="color:#FF8F29"> <span style="font-weight: 700;">{{popData.cost  }}</span> USDT</div>
        </van-collapse-item>

        <van-collapse-item title="流量:"
                           name="1"
                           :readonly="true"
                           :border="true">
          <div> 套餐内:<span style="color:#FF8F29 ;padding-left: 8px;"> 通用流量 <span style="font-weight: 700;">100</span> G</span>
          </div>
          <div> 套餐外:<span style="color:#FF8F29"> <span style="padding-left: 10px;font-weight: 700;">1.5</span> USDT/G</span></div>
        </van-collapse-item>

        <van-collapse-item title="通话:"
                           name="1"
                           :readonly="true"
                           :border="false">
          <div> 套餐内:<span style="color:#FF8F29"> <span style="padding-left: 10px;font-weight: 700;">1000</span> 分钟</span>
          </div>
          <div> 套餐外:<span style="
                    color:#FF8F29"> <span style="padding-left: 10px;font-weight: 700;">0.1</span> USDT/分钟</span></div>

        </van-collapse-item>

      </van-collapse>

    </div>
    <van-button color="#FF8F29"
                type="primary"
                round
                style="margin-top: 20px; padding: 0 45%; font-weight: 700;"
                @click="buySIM()">购买</van-button>
  </van-popup>
  <van-popup v-model:show="showPopupAddrList"
             position="bottom"
             round
             closeable
             close-icon="close"
             :style="{ padding: '20px',height: '50%'}"
             :data="popData"
             class="detail">
    <div style="font-weight: 700;">选择钱包账户</div>
    <div class="add_container">
      <div v-for="(item,index) in walletAddr"
           :key="index"
           class="add_info"
           @click="addrClick()">
        <van-row align="center"
                 justify="space-between">
          <!-- <van-col span="3">
            <div>账户{{ index }}</div>
          </van-col> -->
          <van-col span="18">
            <div style="text-align: left;">{{ item }}</div>
          </van-col>
          <van-col span="6">
            <div> 0 USDT</div>
          </van-col>
        </van-row>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { showConfirmDialog, showDialog } from 'vant'
let bip39 = require('bip39')
let hdkey = require('ethereumjs-wallet').hdkey
let util = require('ethereumjs-util')
export default {
  data() {
    return {
      searchInput: '',
      activeNames: ['1'],
      activeNamesa: ['1'],
      checked: ['c', 'b'],
      hongkong: [
        { num: '+852 81234567', cost: 5, country: '香港', district: '' },
        { num: '+852 95326701', cost: 6, country: '香港', district: '' },
        { num: '+852 94256781', cost: 7, country: '香港', district: '' },
        { num: '+852 84537120', cost: 8, country: '香港', district: '' },
        { num: '+852 88234228', cost: 9, country: '香港', district: '' }
      ],
      canada: {
        'Fort William': [
          {
            num: '+1 807 1497132',
            cost: 10,
            country: '加拿大',
            district: 'Fort William'
          },
          {
            num: '+1 807 2587630',
            cost: 11,
            country: '加拿大',
            district: 'Fort William'
          },
          {
            num: '+1 807 3681028',
            cost: 13,
            country: '加拿大',
            district: 'Fort William'
          },
          {
            num: '+1 807 6873048',
            cost: 16,
            country: '加拿大',
            district: 'Fort William'
          }
        ],
        'Alberta (Northern)': [
          {
            num: '+1 780 64931629',
            cost: 18,
            country: '加拿大',
            district: 'Alberta (Northern)'
          },
          {
            num: '+1 780 85613065',
            cost: 19,
            country: '加拿大',
            district: 'Alberta (Northern)'
          }
        ],
        'Alberta (Southern)': [
          {
            num: '+1 403 85613065',
            cost: 12,
            country: '加拿大',
            district: 'Alberta (Southern)'
          },
          {
            num: '+1 403 85613065',
            cost: 11,
            country: '加拿大',
            district: 'Alberta (Southern)'
          }
        ]
      },
      showPopup: false,
      popData: { num: '', cost: 0, country: '', district: '' },
      columns: [
        { title: '名称', prop: 'name' },
        { title: '年龄', prop: 'age' },
        { title: '地址', prop: 'address' }
      ],
      tdata: [
        { name: '张三', age: 25, address: '北京市' },
        { name: '李四', age: 30, address: '上海市' },
        { name: '王五', age: 28, address: '广州市' }
      ],
      showPopupAddrList: false,
      walletAddr: []
    }
  },
  methods: {
    hongkongClick(index) {
      console.log('hk index', index)
      this.showPopup = true
      this.popData.num = this.hongkong[index].num
      this.popData.cost = this.hongkong[index].cost
      this.popData.country = this.hongkong[index].country
      this.popData.district = this.hongkong[index].district
    },
    canadaClick(district, index) {
      console.log('hk index', index)
      this.showPopup = true
      if (0 == district) {
        this.popData.num = this.canada['Fort William'][index].num
        this.popData.cost = this.canada['Fort William'][index].cost
        this.popData.country = this.canada['Fort William'][index].country
        this.popData.district = this.canada['Fort William'][index].district
      } else if (1 == district) {
        this.popData.num = this.canada['Alberta (Northern)'][index].num
        this.popData.cost = this.canada['Alberta (Northern)'][index].cost
        this.popData.country = this.canada['Alberta (Northern)'][index].country
        this.popData.district =
          this.canada['Alberta (Northern)'][index].district
      } else if (2 == district) {
        this.popData.num = this.canada['Alberta (Southern)'][index].num
        this.popData.cost = this.canada['Alberta (Southern)'][index].cost
        this.popData.country = this.canada['Alberta (Southern)'][index].country
        this.popData.district =
          this.canada['Alberta (Southern)'][index].district
      }
    },
    getKey(key) {
      return JSON.parse(window.localStorage.getItem(key))
    },
    buySIM() {
      console.log('**************', this.getKey('mnemonic'))
      if (this.getKey('mnemonic') == null || this.getKey('mnemonic') == '') {
        showConfirmDialog({
          title: '',
          message: '您还未创建钱包，请先创建钱包。'
        })
          .then(() => {
            // on confirm
            this.$router.push('/mywallet')
          })
          .catch(() => {
            // on cancel
            console.log('cancel buy sim')
          })
      } else {
        if (this.getKey('mnemonic') != null) {
          // 清楚缓存
          this.walletAddr = []
          console.log('wallet: ', this.getKey('mnemonic'), this.getKey('index'))
          let storeIndex = this.getKey('index')
          if (storeIndex != null) {
            console.log('Stored Index', storeIndex)
            for (let i = 0; i <= storeIndex; i++) {
              let addra = this.createWallet(this.getKey('mnemonic'), i)
              this.walletAddr.push(addra)
            }
          }
        }
        this.showPopupAddrList = true
      }
    },
    addrClick() {
      showDialog({
        message: '区块链交易流程上线中，请稍后......',
        theme: 'round-button'
      }).then(() => {
        // on close
        this.showPopupAddrList = false
        this.showPopup = false
      })
    },
    createWallet(mnemonicInput, index) {
      console.log('---------- enter createwallet', mnemonicInput, index)
      //   // 1.生成助记词
      let mnemonic = ''
      if (mnemonicInput != '') {
        mnemonic = mnemonicInput
        console.log(' Use input mnemonic: ', mnemonic, index)
      } else {
        mnemonic = bip39.generateMnemonic()
        console.log(' New mnemonic now: ', mnemonic)
      }

      console.log('助记词：' + mnemonic, '\n')
      //   //传入助记词  即可助记词生成	私钥&地址
      // mnemonic = "reward nation sense clinic catch region vocal perfect turtle heavy among picnic"
      //   //2.将助记词转成seed
      let seed = bip39.mnemonicToSeedSync(mnemonic)
      // //   // //3.通过hdkey将seed生成HD Wallet
      let hdWallet = hdkey.fromMasterSeed(seed)
      console.log('', hdWallet)
      //   //4.生成钱包中在 m/44'/60'/0'/0/0 路径的keypair

      let keyDerivedPath = ''
      if (mnemonicInput == '') {
        keyDerivedPath = "m/44'/60'/0'/0/0"
      } else {
        keyDerivedPath = "m/44'/60'/0'/0/" + index
      }
      console.log(' keyDerivedPath: ', keyDerivedPath)
      let key = hdWallet.derivePath(keyDerivedPath)
      //   //5.从keypair中获取私钥
      let privateKey = util.bufferToHex(key._hdkey._privateKey)
      console.log('私钥：' + privateKey, '\n')
      //   //6.从keypair中获取公钥
      console.log('公钥：' + util.bufferToHex(key._hdkey._publicKey))
      //   //7.使用keypair中的公钥生成地址
      // let address = util.pubToAddress(key._hdkey._publicKey, true)
      // console.log('地址1：' + address, '\n')
      // //   //编码地址
      // address = util.toChecksumAddress(address.toString('hex'))

      // console.log('地址：' + privateKey, '\n')

      let wallet1 = key.getWallet()
      console.log('pubkey:', wallet1.getPublicKey())
      console.log('privatekeyStr:', wallet1.getPrivateKey())
      console.log('pubkey:', wallet1.getPublicKeyString())
      console.log('privatekeyStr:', wallet1.getPrivateKeyString())
      console.log('addr:', wallet1.getAddress())
      console.log('addrStr:', wallet1.getAddressString())

      return (
        wallet1.getAddressString().slice(0, 8) +
        '......' +
        wallet1.getAddressString().slice(-8)
      )
    }
  }
}
</script>
<style scoped lang="less">
.buysim {
  padding: 20px 10px 0px 10px;
  .guanggao {
    // padding: 5px;
    .item {
      padding: 5px;
      border-radius: 10px;
      // border: 2px solid  #FFD8B6";
      border: 2px solid #ffd8b6;
      .tip {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  .xuanze {
    margin-top: 15px;
    text-align-last: left;
    .title {
      margin-top: 5px;
      font-size: 11px;
      font-weight: 700;
    }
    .tip {
      margin-top: 5px;
      font-size: 10px;
    }
  }

  .search {
    margin-top: 15px;
    .van-search {
      padding: 0 !important;
    }
  }

  // .numlist {
  //   .van-collapse .van-cell .van-collapse-item__title .van-cell__title {
  //     justify-content: flex-start; /* 设置标题靠左对齐 */
  //     text-align: left;
  //   }
  // }
  .numlist {
    margin-top: 15px;
    .my-collapse {
      text-align: left;
      justify-content: left; /* 可能需要调整flex布局的justify-content属性 */

      .van-collapse-item {
        :deep(.van-collapse-item__title--expanded img) {
          transform: rotate(-180deg) !important ;
        }

        :deep(.van-collapse-item__title img) {
          transform: rotate(0deg) translateZ(0) !important;
          transition: transform var(--van-collapse-item-duration) !important;
        }
      }

      .nlist {
        padding: 10px;
        border: 1px solid #ffd8b6;
        border-radius: 5px;
        margin-top: 3px;
        background-color: #ffd8b6;
        color: black;
      }
      .nlist:hover {
        bottom: green;
      }
    }
  }

  :deep(.van-collapse-item__title--expanded img) {
    transform: rotate(-180deg) !important;
  }

  :deep(.van-collapse-item__title img) {
    transform: rotate(0deg) translateZ(0);
    transition: transform var(--van-collapse-item-duration);
  }
}

.detail {
  .detail_header {
    margin-top: 10px;
    border: 2px solid #ffd8b6;
    padding: 10px 20px;
    // background-color: green;
    text-align: left;
    border-radius: 10px;
    .fun {
      margin-top: 10px;
      margin-bottom: 10px;
      span img {
        height: 25px;
        margin-right: 10px;
      }
    }
  }

  .tcdetail {
    margin-top: 10px;
    .my-collapsea {
      text-align: left;
      justify-content: left; /* 可能需要调整flex布局的justify-content属性 */

      :deep(.van-collapse-item__title--expanded img) {
        transform: rotate(-180deg) !important;
      }

      :deep(.van-collapse-item__title img) {
        transform: rotate(0deg) translateZ(0);
        transition: transform var(--van-collapse-item-duration);
      }
    }
  }
  .add_container {
    margin: 30px 0;
    .add_info {
      padding: 10px;
      margin-bottom: 5px;
      background-color: #ffd8b6;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  :deep(.van-collapse-item__title--expanded img) {
    transform: rotate(-180deg) !important;
  }

  :deep(.van-collapse-item__title img) {
    transform: rotate(0deg) translateZ(0);
    transition: transform var(--van-collapse-item-duration);
  }
}
</style>

