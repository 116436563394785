<template>
  <div class="my_container">
    <div class="head">
      <div class="title">个人中心</div>
      <van-row align="center">
        <van-col span="5">
          <div>
            <img src="user.png"
                 alt=""
                 height="50px">
          </div>
        </van-col>
        <van-col span="4">
          <div>Someone</div>
        </van-col>
        <van-col span="15">
          <div style="text-align: right; padding-right: 20px;">
            <img src="arrow_right.png"
                 alt=""
                 height="20px">
          </div>
        </van-col>
      </van-row>
    </div>
    <div class="mycenter">
      <van-grid :column-num="2"
                :border="true"
                clickable>
        <van-grid-item text="ddd文字"
                       to="/mywallet">
          <van-image src="wallet.png"
                     style="height: 30px;" />
          <div style="margin-top: 8px; font-size: 12px;font-weight: 700;">钱包</div>
        </van-grid-item>
        <van-grid-item text="ddd文字"
                       to="/myesim">
          <van-image src="sim.png"
                     style="height: 30px;" />
          <div style="margin-top: 8px; font-size: 12px;font-weight: 700;">eSIM</div>
        </van-grid-item>
      </van-grid>
    </div>

  </div>
</template>

<style scoped lang="less">
.my_container {
  // margin-top: 20px;
  padding: 40px 10px 0 10px;
  .head {
    .title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    // background-color: green;
  }
  .mycenter {
    margin-top: 30px;
  }
}
</style>
