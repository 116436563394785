<template>
  <div class="wallet_container">
    <van-nav-bar title="我的钱包"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />
    <van-empty description=""
               v-if="walletAddr.length == 0">
      <template #image>
        <img src="wallet.webp"
             alt="">
      </template>
      <template #description>
        <div style="font-weight: 700;color:#ff9000">您还未生成钱包</div>
      </template>
      <div>
        <van-button round
                    type="primary"
                    class="bottom-button"
                    color="#ff9000"
                    @click="newWallet">创建钱包</van-button>
      </div>
      <div style="margin-top: 20px;">
        <van-button round
                    type="primary"
                    class="bottom-button"
                    color="#ff9000"
                    @click="importMnemonic">导入助记词</van-button>
      </div>
      <div style="margin-top: 20px;">
        <van-button round
                    type="primary"
                    class="bottom-button"
                    color="#ff9000"
                    @click="importPrivateKey">导入私钥</van-button>
      </div>

    </van-empty>

    <div class="add_container">
      <div v-for="(item,index) in walletAddr"
           :key="index"
           class="add_info"
           @click="addrClick(index)">
        <van-row align="center"
                 justify="space-between">
          <!-- <van-col span="3">
            <div>账户{{ index }}</div>
          </van-col> -->
          <van-col span="18">
            <div style="text-align: left;">{{ item }}</div>
          </van-col>
          <van-col span="6">
            <div> 0 USDT</div>
          </van-col>
        </van-row>
      </div>
    </div>

    <van-button round
                type="primary"
                class="bottom-button"
                color="#ff9000"
                @click="newAccount"
                v-if="walletAddr.length > 0">新建账户</van-button>
    <van-dialog v-model:show="showDialog"
                title="助记词"
                show-cancel-button
                confirmButtonColor="#ff9000"
                @cancel="dialogCancel()"
                @confirm="dialogConfirm()">
      <div style="margin: 10px 0;">请妥善保管您的助记词,避免泄露！</div>
      <div style="margin: 10px 0 30px 0;">{{ tipDialog }}</div>
    </van-dialog>
    <van-dialog v-model:show="showDImportMnemonic"
                title="导入助记词"
                show-cancel-button
                confirmButtonColor="#ff9000"
                @cancel="importDialogCancel()"
                @confirm="importDialogConfirm()">
      <!-- <div style="margin: 10px 0;">请妥善保管您的助记词,避免泄露！</div> -->
      <van-field v-model="importedMnemonic"
                 rows="5"
                 autosize
                 type="textarea"
                 placeholder="请输入助记词" />
    </van-dialog>

    <van-popup v-model:show="showPopupAction"
               position="bottom"
               round
               closeable
               close-icon="close"
               :style="{ padding: '20px',height: '20%'}"
               :data="popData">
      <div style="margin-bottom: 15px;">{{ walletAddr[clickAddrIndex] }}</div>
      <van-row align="center"
               justify="space-between">
        <van-col span="12">
          <div><img src="transfer.png"
                 alt=""
                 width="30%"></div>
          <div>转账</div>
        </van-col>
        <van-col span="12">
          <div><img src="receive.png"
                 alt=""
                 width="30%"></div>
          <div>收款</div>
        </van-col>
      </van-row>
    </van-popup>
  </div>
</template>

<script>
let bip39 = require('bip39')
let hdkey = require('ethereumjs-wallet').hdkey
let util = require('ethereumjs-util')
import { showDialog } from 'vant'
export default {
  data() {
    return {
      list: [
        {
          num: '+852 81234567',
          cost: 5,
          country: '香港',
          district: '',
          status: 0
        },
        {
          num: '+852 95326701',
          cost: 6,
          country: '香港',
          district: '',
          status: 0
        },
        {
          num: '+1 807 1497132',
          cost: 10,
          country: '加拿大',
          district: 'Fort William',
          status: 1
        },

        {
          num: '+1 780 64931629',
          cost: 18,
          country: '加拿大',
          district: 'Alberta (Northern)',
          status: 0
        },
        {
          num: '+1 403 85613065',
          cost: 11,
          country: '加拿大',
          district: 'Alberta (Southern)',
          status: 0
        }
      ],
      haveWallet: false,
      walletAddr: [],
      // mnemonic dialog
      showDialog: false,
      tipDialog: '',
      // 导入助记词弹出框
      showDImportMnemonic: false,
      importedMnemonic: '',
      showPopupAction: false,
      clickAddrIndex: 0
    }
  },
  mounted() {
    if (this.getKey('mnemonic') != null) {
      this.haveWallet = true
      console.log('------------------------ have wallet?', this.haveWallet)
      console.log('wallet: ', this.getKey('mnemonic'), this.getKey('index'))
      let storeIndex = this.getKey('index')
      if (storeIndex != null) {
        console.log('Stored Index', storeIndex)
        for (let i = 0; i <= storeIndex; i++) {
          let addra = this.createWallet(this.getKey('mnemonic'), i)
          this.walletAddr.push(addra)
        }
      }
    }
    // else {
    //   this.haveWallet = false
    //   console.log('------------------------ have wallet?', this.haveWallet)
    //   let addr = this.createWallet('', 0)
    //   this.walletAddr.push(addr)
    // }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    onLoad() {
      return
    },

    getKey(key) {
      return JSON.parse(window.localStorage.getItem(key))
    },
    putKey(key, value) {
      // return JSON.parse(window.localStorage.getItem(key))
      return window.localStorage.setItem(key, JSON.stringify(value))
    },

    dialogCancel() {
      console.log(' cancel new wallet')
    },
    dialogConfirm() {
      console.log(' confirm new wallet')
      let addr = this.createWallet(this.tipDialog, 0)
      this.walletAddr.push(addr)
    },
    // 用户主动生成钱包
    newWallet() {
      this.tipDialog = this.generateMnemonic('', 0)
      this.showDialog = true
    },
    // 导入助记词
    importMnemonic() {
      this.importedMnemonic = ''
      this.showDImportMnemonic = true
      // skull shoot advice pluck vanish total toe only fish tone manual crime
    },
    // 导入私钥
    importPrivateKey() {
      showDialog({
        message: '导入私钥流程上线中，请稍后......',
        theme: 'round-button'
      }).then(() => {
        // on close
        console.log('confirm')
      })
    },
    importDialogCancel() {
      console.log(' Cancel imported mnemonic: ')
    },
    importDialogConfirm() {
      console.log(' Imported mnemonic: ', this.importedMnemonic)
      let addr = this.createWallet(this.importedMnemonic, 0)
      this.walletAddr.push(addr)
    },
    generateMnemonic() {
      let mnemonic = bip39.generateMnemonic()
      console.log(' New mnemonic now: ', mnemonic)
      console.log('助记词：' + mnemonic, '\n')
      return mnemonic
    },
    newAccount() {
      if (this.getKey('mnemonic') != null) {
        let storeIndex = this.getKey('index')
        if (storeIndex != null) {
          console.log('add account: ', this.getKey('mnemonic'), storeIndex)
          let addra = this.createWallet(this.getKey('mnemonic'), storeIndex + 1)
          this.walletAddr.push(addra)
          this.putKey('index', storeIndex + 1)
        }
      } else {
        console.log(' Error: must create wallet before new account')
      }
    },
    createWallet(mnemonicInput, index) {
      console.log('---------- enter createwallet', mnemonicInput, index)
      //   // 1.生成助记词
      let mnemonic = ''
      if (mnemonicInput != '') {
        mnemonic = mnemonicInput
        console.log(' Use input mnemonic: ', mnemonic, index)
      } else {
        mnemonic = bip39.generateMnemonic()
        console.log(' New mnemonic now: ', mnemonic)
      }

      console.log('助记词：' + mnemonic, '\n')
      //   //传入助记词  即可助记词生成	私钥&地址
      // mnemonic = "reward nation sense clinic catch region vocal perfect turtle heavy among picnic"
      //   //2.将助记词转成seed
      let seed = bip39.mnemonicToSeedSync(mnemonic)
      // //   // //3.通过hdkey将seed生成HD Wallet
      let hdWallet = hdkey.fromMasterSeed(seed)
      console.log('', hdWallet)
      //   //4.生成钱包中在 m/44'/60'/0'/0/0 路径的keypair

      let keyDerivedPath = ''
      if (mnemonicInput == '') {
        keyDerivedPath = "m/44'/60'/0'/0/0"
      } else {
        keyDerivedPath = "m/44'/60'/0'/0/" + index
      }
      console.log(' keyDerivedPath: ', keyDerivedPath)
      let key = hdWallet.derivePath(keyDerivedPath)
      //   //5.从keypair中获取私钥
      let privateKey = util.bufferToHex(key._hdkey._privateKey)
      console.log('私钥：' + privateKey, '\n')
      //   //6.从keypair中获取公钥
      console.log('公钥：' + util.bufferToHex(key._hdkey._publicKey))
      //   //7.使用keypair中的公钥生成地址
      // let address = util.pubToAddress(key._hdkey._publicKey, true)
      // console.log('地址1：' + address, '\n')
      // //   //编码地址
      // address = util.toChecksumAddress(address.toString('hex'))

      // console.log('地址：' + privateKey, '\n')

      let wallet1 = key.getWallet()
      console.log('pubkey:', wallet1.getPublicKey())
      console.log('privatekeyStr:', wallet1.getPrivateKey())
      console.log('pubkey:', wallet1.getPublicKeyString())
      console.log('privatekeyStr:', wallet1.getPrivateKeyString())
      console.log('addr:', wallet1.getAddress())
      console.log('addrStr:', wallet1.getAddressString())

      // store mnemonic
      this.putKey('mnemonic', mnemonic)
      this.putKey('index', index)

      // this.walletAddr.push(wallet1.getAddressString())
      // str.slice(0, 5)
      return (
        wallet1.getAddressString().slice(0, 8) +
        '......' +
        wallet1.getAddressString().slice(-8)
      )
    },
    addrClick(index) {
      console.log('click address')
      this.clickAddrIndex = index
      this.showPopupAction = true
    }
  }
}
</script>

<style scoped lang="less">
.wallet_container {
  padding: 40px 10px 0 10px;
  /* /deep/的意思大概为深入的 */
  //修改文字颜色
  /deep/ .van-nav-bar__text {
    color: #000000;
  }
  //修改箭头颜色
  /deep/ .van-icon-arrow-left {
    color: var(--nav-bar-icon-color, #ff9000) !important;
  }
  //修改背景颜色
  /deep/ .van-nav-bar {
    // background-color: #e40000;
  }
  .head {
    .title {
      font-weight: 700;
      margin-bottom: 10px;
    }
    // background-color: green;
  }
  .bottom-button {
    width: 160px;
    height: 40px;
    font-weight: 700;
  }
  .add_container {
    margin: 30px 0;
    .add_info {
      padding: 10px;
      margin-bottom: 5px;
      background-color: #ffd8b6;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
</style>
